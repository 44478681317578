import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, Link, withPrefix } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

export const CareersPostTemplate = ({
  content,
  contentComponent,
  date,
  title,
  helmet,
  author,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <>
      <div className="hero bg-indigo-800  md:pb-24 md:pt-48 pb-10 pt-24">
        {helmet || ""}

        <div className="max-w-screen-xl mx-auto px-6">
          <Link className="focus:outline-none focus:text-white hover:text-white flex items-center text-indigo-400 text-sm uppercase" to="/careers">
          <svg
            className="mr-2"
            fill="#AAADFB"
            height=".7em"
            width=".7em"
          >
          <use xlinkHref="#icon-chevron-left"></use>
          </svg>
          Back to careers
        </Link>
          <h1
            className="font-serif leading-tight max-w-3xl md:ml-4 md:text-7xl mt-8 text-4xl w-11/12"
          >
            {title}
          </h1>

          <div
            className="inline-flex items-center md:ml-4 mt-2 text-indigo-400 text-sm"
          >
            <p>Posted: {date}</p>
            {/* <p className="mx-2 text-xl">·</p>
          <p>{author}</p> */}
          </div>
        </div>
      </div>
      <div className="bg-gray-100 pb-24">
        <div className="pb-6 max-w-screen-xl md:pb-40 md:pt-32 mx-auto pb-16 pt-12 px-6 md:flex md:flex-wrap">
          <div class="font-serif md:leading-tight mb-6 md:mr-8 md:text-5xl md:w-1/3 text-2xl text-gray-600 w-2/3">
            About the job
          </div>
          <div className="pb-6 md:leading-loose md:pr-24 md:text-lg md:w-3/5 leading-relaxed text-gray-600 md:text-gray-800 w-10/12">
            <PostContent className="text-content" content={content} />
          </div>
        </div>
      </div>
    </>
  );
};

CareersPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  date: PropTypes.string,
  author: PropTypes.string,
  helmet: PropTypes.object,
};

const CareersPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <CareersPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        //  description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Careers | CVision AI">
            <title>{`${post.frontmatter.title}`}</title>
            <meta name="description" content={(typeof post.frontmatter.og_description !== "undefined" && post.frontmatter.og_description !== "" && post.frontmatter.og_description !== null) ? `${post.frontmatter.og_description}` : ``} />
            <meta property="og:title" content={`${post.frontmatter.title}`} />
            <meta
              property="og:image"
              content={(typeof post.frontmatter.og_image !== "undefined" && post.frontmatter.og_image !== "" && post.frontmatter.og_image !== null) ? `${post.frontmatter.og_image}` : `${withPrefix('/')}img/og-image.jpg`}
            />
          </Helmet>
        }
        date={post.frontmatter.date}
        title={post.frontmatter.title}
        author={post.frontmatter.author}
        media={post.frontmatter.media}
      />
    </Layout>
  );
};

CareersPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default CareersPost;

export const pageQuery = graphql`
  query CareersPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        author
      }
    }
  }
`;
